/**
 * Const with params element
 * Hero type3 component
 * Display the hero type 3: h1 + text + img at right with lightbox video
 */
/* Import section */
import React from "react"
import Boxctavideo from "../components/boxctavideo"
import "../styles/components/herotype3.scss"

/* Declaration function */
const Herotype3 = ( { h1title, textIntro, videoHero, colorBar} ) => {
  return (
      <div className="wrap-hero-type3">
        <div className="box-content-hero-type3">
          <div className="wrap-maincontent">
          <h1 dangerouslySetInnerHTML={{__html:h1title}} />
          </div>
          <div className="wrap-box-intro">
            <div className="box-intro">
              <div className={`separator-bar ${colorBar}`}></div>
              <div className="text-intro" dangerouslySetInnerHTML={{ __html:textIntro}} />
            </div>
          </div>
        </div>
        <div className="box-visual-hero-type3">
          <div className="videolightbox-hero-type3">
            <Boxctavideo text_cta="Play Demo" video_title="hello health US demo" video_url={videoHero} classbtn_video="round" />
          </div>
        </div>
      </div>
  )
}

/* Export function */
export default Herotype3

