/**
 * demo page
 */
/* Import section */
import React from "react"
import { graphql } from "gatsby"
import Bannerwithcta from "../components/bannerwithcta"
import Titledecorative from "../components/titledecorative"
import Herotype3 from "../components/herotype3"
import Hubspot from "../components/hubspotform"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/demo-5minute.scss"


/* Page function declaration */
const Demo5minutepage = ({data}) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageDemo

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "getstarted1"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        datastructured="demo"
      />

      {/* Hero section */}
      <section className="section-hero-demo top-section">
        <Herotype3 h1title={dataAcf.titleHeroDemo} textIntro={dataAcf.textHeroDemo} videoHero={dataAcf.videoHeroDemo} colorBar="blue" />
      </section>

      {/*Demo details section */}
      <section className="section-details-demo">
        <div className="wrap-intro wrap-maincontent">
          <Titledecorative title={dataAcf.titleSnippetDemo} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textSnippetDemo}} />
          </div>
        </div>
      </section>

      {/*Demo request form section */}
      <section className="section-request-live-demo">
        <div className="wrap-maincontent wrap-form">
          <h2><span>{dataAcf.titleRequestLiveDemo}</span></h2>
          <div className="text-form" dangerouslySetInnerHTML={{ __html:dataAcf.textRequestLiveDemo}} />
          <Hubspot formIdentifier={dataAcf.formIdHubspotLiveDemo} />
        </div>
      </section>

      {/* Banner CTA Demo */}
      <Bannerwithcta
        classBanner="section-banner-demo"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "demo-5minute"}) {
      acfPageDemo {
        titleHeroDemo
        videoHeroDemo
        textHeroDemo
        titleSnippetDemo
        textSnippetDemo
        titleRequestLiveDemo
        textRequestLiveDemo
        formIdHubspotLiveDemo
      }
      title
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
/* Export page informations */
export default Demo5minutepage
